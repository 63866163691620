@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


.active-button {
  background-color: blue;
  color: white;
}

.inactive-button {
  background-color: gray;
  color: white;
  cursor: not-allowed;
}